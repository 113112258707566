import LogoSmall from '../../assets/logo-1280x351.png'
import LogoMedium from '../../assets/logo-1280x351.png'
import LogoLarge from '../../assets/logo-1280x351.png'

const LogoHorizontal = () => {
    const srcSet = `${LogoMedium} 2x, ${LogoLarge} 3x`
    const style = {
        width: '480px',
        maxWidth: '100%',
        padding: '0.5em',
        marginBottom: '1.5em'
    }
    return (
        <figure>
            <img src={ LogoSmall } alt="" srcSet={ srcSet } style={ style }/>
        </figure>
    )
}

export default LogoHorizontal
