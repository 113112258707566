const HomeBackground = () => (
    <div>
        <h4>Background</h4>
        <p>I completed a three year Software Development course at Nelson Mandela University in Port
            Elizabeth, South Africa. My first job was working with PHP, which is where my affair with Web
            programming started.</p>
    </div>
)

export default HomeBackground
