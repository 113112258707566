import { AppSections } from "./AppSections";

export const NavItems = [
    {
        text: 'Home',
        path: AppSections.Home,
        icon: 'home'
    },
    {
        text: 'Skills',
        path: AppSections.Skills,
        icon: 'construction'
    },
    {
        text: 'Work',
        path: AppSections.Work,
        icon: 'timeline'
    },
    {
        text: 'Contact',
        path: AppSections.Contact,
        icon: 'mail'
    },
]