import ContactAddress from "./ContactAddress";
import ContentSectionHeading from "../ContentSectionHeading";

const Contact = () => {
    return (
        <section>
            <ContentSectionHeading icon="email" text="Want to talk?"/>

            <p>
                Feel free to drop me an email any time you would like to talk about prospective projects. I like to evaluate
                each project individually to ensure it will interest me. This way you can rest assured that I will always give my 100% in working on the project and ensuring its maintenance and stability.
            </p>

            <br/>

            <ContactAddress/>
        </section>
    )
}

export default Contact
