import styles from './WorkTags.module.css'

const WorkTags = ({ tags }) => {
    const tagsRender = tags.map(tag => (
        <span className={styles.WorkTag} key={ tag }>{ tag }</span>
    ))
    return (
        <div className={styles.WorkTags}>
            { tagsRender }
        </div>
    )
}

export default WorkTags
