const containerStyles = {
    padding: '2rem 0',
    display: 'flex',
    justifyContent: 'center'
}

const separatorStyle = {
    backgroundColor: 'var(--col-green-shaded)',
    width: '200px',
    height: '.15rem',
    borderRadius: '.5rem'
}

const ContentSectionSeparator = () => (
    <div style={containerStyles}>
        <div style={separatorStyle} />
    </div>
)

export default ContentSectionSeparator