import ContentSectionHeading from "../ContentSectionHeading";
import styles from './Work.module.css'
import { MyWork } from '../../../constants/MyWork'
import WorkItem from "./WorkItem";

const Work = () => {

    const workRender = MyWork.map(work => (
        <WorkItem work={ work } key={ work.id } />
    ))


    return (
        <section className={styles.Work}>
            <ContentSectionHeading icon="timeline" text="Work" />
            <p>Below yuo can find some information on work I have done.
                This includes my personal blog where I try to post as regularly as I can.
                The largest project that is still running in production,
                Afribus is a large enterprise system that is currently running in Zambia. You can also find my Gitlab and Github repository links. </p>
            <br/>
            <div className={ styles.WorkItems }>
                { workRender }
            </div>
        </section>
    )
}

export default Work
