import { createContext, useState } from "react";
import { AppSections } from '../../constants/AppSections'

export const AppContext = createContext({})

export const AppProvider = ({ children }) => {

    const [ activeSection, setActiveSection ] = useState( AppSections.Home )

    const AppState = {
        activeSection,
        setActiveSection
    }

    return (
        <AppContext.Provider value={ AppState }>
            { children }
        </AppContext.Provider>
    )
}

export default AppProvider
