import Progress from "./Progress";

const Icon = ({ iconClass, size, completion }) => {

    const styles = {
        fontSize: size,
        padding: '1rem',
        display: 'inline-flex',
        flexDirection: 'column',
        border: '1px solid #efefef',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '.5rem',
        marginBottom: '1rem',
        borderRadius: '.25rem',
        backgroundColor: 'white'
    }

    const iconStyle = {
        display: 'block'
    }

    return (
        <div style={ styles }>
            <i style={ iconStyle } className={ `${iconClass} colored` } />
            <Progress completePercentage={completion} />
        </div>

    )
}

export default Icon
