// Point Of Sale
import AfribusPos001 from '../assets/projects/afribus-pos/project-thumb-afribus-001.jpg'
import AfribusPos002 from '../assets/projects/afribus-pos/project-thumb-afribus-002.jpg'
import AfribusPos003 from '../assets/projects/afribus-pos/project-thumb-afribus-003.jpg'
import AfribusPos004 from '../assets/projects/afribus-pos/project-thumb-afribus-004.jpg'

// Wide Area
import AfribusWA001 from '../assets/projects/afribus-wa/project-thumb-afribus-wa-001.jpg'
import AfribusWA002 from '../assets/projects/afribus-wa/project-thumb-afribus-wa-002.jpg'
import AfribusWA003 from '../assets/projects/afribus-wa/project-thumb-afribus-wa-003.jpg'
import AfribusWA004 from '../assets/projects/afribus-wa/project-thumb-afribus-wa-004.jpg'

// VueTrivia
import VueTrivia001 from '../assets/projects/vue-trivia/project-thumb-triviamaster-001.jpg'
import VueTrivia002 from '../assets/projects/vue-trivia/project-thumb-triviamaster-002.jpg'
import VueTrivia003 from '../assets/projects/vue-trivia/project-thumb-triviamaster-003.jpg'
import VueTrivia004 from '../assets/projects/vue-trivia/project-thumb-triviamaster-004.jpg'
import VueTrivia005 from '../assets/projects/vue-trivia/project-thumb-triviamaster-005.jpg'

// Gitlab
import Gitlab001 from '../assets/projects/gitlab/gitlab-001.jpg';

// Github
import Github001 from '../assets/projects/github/github-001.jpg';

// Blog
import Blog001 from '../assets/projects/blog/blog-001.jpg';

export const AfribusPOSImages = [
    AfribusPos001,
    AfribusPos002,
    AfribusPos003,
    AfribusPos004
]

export const AfribusWAImages = [
    AfribusWA001,
    AfribusWA002,
    AfribusWA003,
    AfribusWA004
]

export const VueTriviaImages = [
    VueTrivia001,
    VueTrivia002,
    VueTrivia003,
    VueTrivia004,
    VueTrivia005,
]

export const GitLabImages = [
    Gitlab001
]

export const GitHubImages = [
    Github001
]

export const BlogImages = [
    Blog001
]
