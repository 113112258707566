import { useContext, useEffect, useRef } from "react"
import { AppSections } from "../../constants/AppSections"
import Contact from "../ContentSections/Contact/Contact"
import Home from "../ContentSections/Home/Home"
import Skills from "../ContentSections/Skills/Skills"
import Work from "../ContentSections/Work/Work"
import { AppContext } from "../Context/AppContext"
import { ScrollToRef } from "../../utils/ScrollTo"
import styles from './Content.module.css'
import ContentSectionSeparator from "../ContentSections/ContentSectionSeparator";
import ParticleEffect from "../ParticleEffect/ParticleEffect";

const Content = () => {

    const { activeSection } = useContext(AppContext)
    const contentRef = useRef( null )
    const homeSectionRef = useRef(null)
    const skillsSectionRef = useRef(null)
    const workSectionRef = useRef(null)
    const contactSectionRef = useRef(null)

    useEffect(() => {
        switch (activeSection) {
            case AppSections.Home:
                ScrollToRef(contentRef, homeSectionRef)
                break
            case AppSections.Skills:
                ScrollToRef(contentRef, skillsSectionRef)
                break
            case AppSections.Work:
                ScrollToRef(contentRef, workSectionRef)
                break
            case AppSections.Contact:
                ScrollToRef(contentRef, contactSectionRef)
                break
            default:
                break
        }
    }, [activeSection])


    return (
        <div style={ { position: 'relative', zIndex:'80' } }>

            <ParticleEffect />

            <main className={styles.Content} ref={ contentRef }>

                <section className={styles.ContentSection} ref={homeSectionRef}>
                    <Home />
                </section>

                <ContentSectionSeparator />

                <section className={styles.ContentSection} ref={skillsSectionRef}>
                    <Skills />
                </section>

                <ContentSectionSeparator />

                <section className={styles.ContentSection} ref={workSectionRef}>
                    <Work />
                </section>

                <ContentSectionSeparator />

                <section className={styles.ContentSection} ref={contactSectionRef}>
                    <Contact />
                </section>
            </main>
        </div>

    )
}

export default Content
