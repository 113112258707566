export const ParticleOptions = {
    background: {
        color: {
            value: "transparent",
        },
    },
    fpsLimit: 24,
    particles: {
        color: {
            value: "#8be8ac",
        },
        collisions: {
            enable: false,
        },
        move: {
            direction: "top",
            enable: true,
            outMode: "bounce",
            random: false,
            speed: 0.25,
            straight: false,
        },
        number: {
            density: {
                enable: true,
                value_area: 400,
            },
            value: 20,
        },
        opacity: {
            value: 0.5,
        },
        shape: {
            type: "circle",
        },
        size: {
            random: true,
            value: 4,
        },
    },
    detectRetina: true,
}
