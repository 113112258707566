import styles from './Skills.module.css'
import Icon from "../../Icon/Icon";
import ContentSectionHeading from "../ContentSectionHeading";

const ICON_SIZE = '3.5rem'

const Skills = () => {

    return (
        <section>
            <ContentSectionHeading icon="construction" text="Skills" />

            <div className={ styles.Skills }>
                <h4 className="mt-0">HTML, CSS and JavaScript</h4>
                <p>
                    As the case is with all web developers, HTML5, CSS and JavaScript forms the foundation of my experience and skill set. I have
                    experience working on a variety of web projects that used HTML, CSS3, and JavaScript at its core.
                </p>

                <br/>

                <Icon iconClass="devicon-html5-plain" size={ ICON_SIZE } completion="60" />
                <Icon iconClass="devicon-css3-plain" size={ ICON_SIZE } completion="60"  />
                <Icon iconClass="devicon-javascript-plain" size={ ICON_SIZE } completion="80"  />

                <br/>

                <h5 className="mb-0">HTML and CSS</h5>

                <p>
                    I am dedicated to write semantic HTML markup that is easy to read and follow. When writing CSS I tend to avoid
                    CSS frameworks and UI kits. Even though I believe these are fantastic for prototyping, real work projects often
                    require very custom designed elements and "hacking" the CSS Framework or UI Kit often proves more troublesome than
                    just writing the CSS yourself.
                </p>

                <h5 className="mb-0">JavaScript</h5>
                <p>
                    I have a strong foundation in JavaScript and understand the fundamentals. My experience has allowed me to gain an
                    understanding of functions, prototypes, scoped variables, classes or functional constructors
                    and design patterns like the factory design.
                </p>

                <p>I also work comfortably with higher order functions and have working
                    knowledge of managing execution context in applications.</p>

                <h4>Angular and TypeScript</h4>
                <Icon iconClass="devicon-typescript-plain" size={ ICON_SIZE } completion="75"  />
                <Icon iconClass="devicon-angularjs-plain" size={ ICON_SIZE } completion="80"  />

                <h4>React and Vue</h4>
                <Icon iconClass="devicon-react-original" size={ ICON_SIZE } completion="50"  />
                <Icon iconClass="devicon-vuejs-plain-wordmark" size={ ICON_SIZE } completion="55"  />

                <h4>Ionic and Mobile development</h4>

                <Icon iconClass="devicon-ionic-original" size={ ICON_SIZE } completion="90"  />


                <h4>DevOps</h4>
                <Icon iconClass="devicon-git-plain" size={ ICON_SIZE } completion="80"  />
                <Icon iconClass="devicon-gitlab-plain-wordmark" size={ ICON_SIZE } completion="60"  />
                <Icon iconClass="devicon-heroku-plain-wordmark" size={ ICON_SIZE } completion="40"  />
                <Icon iconClass="devicon-amazonwebservices-plain-wordmark" size={ ICON_SIZE } completion="30"  />

            </div>

        </section>
    )
}

export default Skills
