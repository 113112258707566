const HomeInterests = () => (
    <div>
        <h4>Interests</h4>
        <p>
            <i>At this point, my website is starting to look like a dating profile.</i> Even so, I enjoy
            learning about
            all the technologies available to create new content for the internet. As most developers would
            agree (not all) - coffee ☕️ is essential to writing code.
        </p>

        <p>I have a wonderful doggo named Oliver 🐶. Getting out into the forest to grab some fresh air is a
            great time to think about code.</p>
    </div>
)

export default HomeInterests
