const progressContainerStyle = {
    width: '100%',
    height: '5px',
    backgroundColor: '#efefef',
    borderRadius: '.25rem',
    marginTop: '.5rem',
    overflow: 'hidden'
}

const Progress = ({ completePercentage = 0 }) => {

    const progressStyle = {
        width: `${completePercentage}%`,
        backgroundColor: 'var(--col-green-shaded)',
        height: 'inherit',

    }




    return (
        <div style={ progressContainerStyle }>
            <div style={ progressStyle } />
        </div>
    )
}

export default Progress