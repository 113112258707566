const HomeAbout = () => (
    <div>
        <h4>About me</h4>
        <p>My name is Dewald Els and I like to write code in JavaScript. I've been programming using web
            technologies for the last 10 years.</p>

        <p>
            Recently I made a move from South Africa to Oslo, Norway to become a JavaScript teacher. My work
            focuses on teaching students the fundamentals of front-end development using modern JavaScript
            technologies.
        </p>
    </div>
)
export default HomeAbout
