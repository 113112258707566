import Particles from "react-tsparticles";
import styles from './ParticleEffect.module.css'
import {ParticleOptions} from "../../constants/Particles";

const ParticleEffect = () => (
    <div className={styles.ParticleEffect}>
        <Particles id="tsparticles" options={ParticleOptions}/>
    </div>
)
export default ParticleEffect
