import {
    AfribusPOSImages,
    AfribusWAImages,
    BlogImages,
    GitHubImages,
    GitLabImages,
    VueTriviaImages
} from "./MyWorkImages";

export const MyWork = [
    {
        id: '6',
        title: 'Blog - Mostly JavaScript',
        intro: 'My personal blog where I write coding posts.',
        tags: ['javascript', 'blog', 'writing'],
        link: 'https://blog.dewaldels.com',
        linkText: 'Mostly JavaScript Blog',
        images: [BlogImages]
    },
    {
        id: '1',
        title: 'Afribus BusPOS',
        intro: 'A Point of Sale application that runs as a Progressive Web Application using Ionic Framework. The application supports real-time sales reporting, bus ticket availability and has a built-in “On-board” mode for terminals that are used in a bus.',
        description: 'Afribus POS is a sophisticated web application written in Angular using Ionic Framework. It allows users to sell bus tickets in real-time. The project also contains a admin system where routes and prices can be configured.\n' +
            'Afribus caters for any customer who has a bus company and would like to onboard using the Afribus POS system.',
        tags: ['javascript', 'angular', 'css', 'ionic framework', 'rest', 'pos'],
        images: AfribusPOSImages
    },
    {
        id: '2',
        title: 'Afribus Wide Area Sales',
        intro: `Afribus is a bus ticketing system that provides an end-to-end service to sell tickets as a bus operator. This Afribus wide area sales application allows users to pre-book bus tickets and pay at participating vendors, including Airtel and MTN Mobile Money. The site has WhatsApp integration for easy support and booking management.`,
        description: '',
        tags: ['javascript', 'angular', 'css', 'ionic framework', 'rest', 'payments'],
        images: AfribusWAImages
    },
    {
        id: '3',
        title: 'Vue Trivia Game',
        intro: 'The Vue Trivia Game is a personal project I created to learn VueJS. It integrates the OpenDB Trivia API. I used the Nes.css library and Howler to manage the audio.',
        description: 'The Vue Trivia Game is a personal project I created to learn VueJS. It integrates the OpenDB Trivia API. I used the Nes.css library and Howler to manage the audio.',
        tags: ['javascript', 'vue', 'api', 'game', 'audio', 'howler'],
        images: VueTriviaImages
    },
    {
        id: '4',
        title: 'GitLab',
        intro: 'My GitLab account with public source code of small projects.',
        tags: ['javascript', 'git', 'html', 'dev ops'],
        link: 'https://gitlab.com/dewaldcels',
        linkText: 'Gitlab Repository',
        images: [GitLabImages]
    },
    {
        id: '5',
        title: 'Github',
        intro: 'My Github account with public source code of small projects.',
        tags: ['javascript', 'git', 'html', 'dev ops'],
        link: 'https://github.com/dewald-els',
        linkText: 'Github Repository',
        images: [GitHubImages]
    }

]
