import styles from "./ContactAddress.module.css";

const ContactAddressItem = ({ icon, children }) => (
    <li className={styles.ContactAddressListItem}>
        <div className={styles.ContactAddressIcon}>
            <i className="material-icons col-green">{ icon }</i>
        </div>
        { children }
    </li>
)

export default ContactAddressItem