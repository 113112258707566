import LogoHorizontal from "../../Logo/LogoHorizontal";
import styles from './HomeHeader.module.css'

const HomeHeader = () => {
    const text = 'JavaScript'
    const animatedText = text.split('').map((letter, i) => (
        <span key={i} className={ `${ styles.ShadowBounce } ani-del-${i*100}` }>{ letter }</span>
    ))

    return (
        <header className={styles.HomeHeader}>

            <LogoHorizontal/>

            <h1 className="mt-0 line-h-1">I program in <span className={styles.AnimatedText}>{ animatedText }</span></h1>
            <h5 className="subtitle">Full Stack Web Development</h5>

            <b className="text-shadow"><small>Scroll down</small></b>

            <div className={styles.float}>
                <i className="material-icons">arrow_downward</i>
            </div>


        </header>
    )
}

export default HomeHeader