import HomeHeader from "./HomeHeader";
import HomeAbout from "./HomeAbout";
import HomeBackground from "./HomeBackground";
import HomeInterests from "./HomeInterests";

const Home = () => {
    return (
        <section>
            <HomeHeader/>
            <HomeAbout/>
            <HomeBackground/>
            <HomeInterests/>
        </section>
    )
}

export default Home
