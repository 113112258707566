const iconStyles = {
    marginRight: '1rem'
}

const ContentSectionHeading = ({ icon, text }) => (
    <h1 className="heading-with-icon">
        <i className="material-icons section-icon col-green" style={ iconStyles }>{ icon }</i>
        { text }
    </h1>
)

export default ContentSectionHeading