import styles from './WorkItem.module.css'
import WorkTags from "./WorkTags";
import Card from "../../Card/Card";
import CardContent from "../../Card/CardContent";

const WorkItem = ({ work }) => {
    return (
        <div className={ styles.WorkItem }>
            <Card>
                <img src={ work.images[0] } alt={ work.title } className={ styles.WorkCoverImage }/>
                <CardContent>
                    <h4 className="mt-0">{ work.title }</h4>
                    <p>{ work.intro }</p>
                    { work.link &&
                        <p>
                            <a target="_blank" rel="noreferrer" href={ work.link }>{ work.linkText }</a>
                            <br/>
                        </p>
                    }
                    <WorkTags tags={ work.tags } />
                </CardContent>
            </Card>
        </div>
    )
}

export default WorkItem
