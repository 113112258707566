import { useContext } from 'react'
import { NavItems } from '../../constants/NavItems'
import { AppContext } from '../Context/AppContext'
import styles from './Navbar.module.css'

const Navbar = () => {

    const { activeSection, setActiveSection } = useContext( AppContext )

    const items = NavItems.map(item => {
        const className = [ styles.NavbarItem ]
        if (item.path === activeSection) {
            className.push(styles.NavbarItemActive);
        }

        return (
            <li key={ item.text } className={ className.join(' ') } onClick={ ()=> setActiveSection( item.path ) }>
                    <i className="material-icons">{ item.icon }</i>
                    {item.text}
            </li>
        );
    })

    return (
        <nav className={ styles.Navbar }>
            <ul className={ styles.NavbarItems }>
                { items }
            </ul>
        </nav>
    )
}

export default Navbar;