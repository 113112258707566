import styles from './ContactAddress.module.css'
import Card from "../../Card/Card";
import CardContent from "../../Card/CardContent";
import ContactAddressItem from "./ContactAddressItem";

const ContactAddress = () => (
    <Card>
        <CardContent>
            <address className={styles.ContactAddress}>
                <h4 className="p-0 mt-0">How can you find me?</h4>
                <ul className={styles.ContactAddressList}>
                    <ContactAddressItem icon="mail">
                        <a href='mailto:dewaldifels@gmail.com'>dewaldifels@gmail.com</a>
                    </ContactAddressItem>
                    <ContactAddressItem icon="location_on">
                        Oslo, Norway
                    </ContactAddressItem>
                </ul>
            </address>
        </CardContent>
    </Card>

)

export default ContactAddress